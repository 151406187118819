<template>
  <div>
    <!-- 商户基本信息 -->
    <p class="subtitle">商户基本信息</p>
    <table class="lattice">
      <tr>
        <td>HP商户号:</td>
        <td colspan="3">{{ info.no || '-' }}</td>
      </tr>
      <tr>
        <td>我司签约主体:</td>
        <td>
          <template v-if="info.sign_sub === 'HK'">香港华融</template>
          <template v-else-if="info.sign_sub === 'AU'">TransferEasy AU</template>
          <template v-else-if="info.sign_sub === 'NZ'">TransferEasy NZ</template>
          <template v-else-if="info.sign_sub === 'US'">TransferEasy USA</template>
          <template v-else-if="info.sign_sub === 'PH'">TransferEasy PHL</template>
          <template v-else>-</template>
        </td>
        <td>所属代理商:</td>
        <td>{{ $route.query.agent_name || '-' }}</td>
      </tr>
      <tr>
        <td>公司主体名称:</td>
        <td>{{ info.company_name || '-' }}</td>
        <td>注册国家/地区:</td>
        <td>{{ info.register_country | CouZH }}</td>
      </tr>
      <tr>
        <td>
          公司注册证书编号:<br />
          (税务登记号):
        </td>
        <td>{{ info.register_certificate || '-' }}</td>
        <td>商户简称:</td>
        <td>{{ info.merchant_short_name || '-' }}</td>
      </tr>
      <tr>
        <td>商户名称:</td>
        <td>{{ info.business_call || '-' }}</td>
        <td>平均单笔交易金额:</td>
        <td>{{ info.amount_average || '-' }}</td>
      </tr>
      <tr>
        <td>企业主要股东ID:</td>
        <td>{{ info.juridical_man_code || '-' }}</td>
        <td>商户类别:</td>
        <td>
          <template v-if="info.merchant_offline === 'ONLINE'">线上</template>
          <template v-else-if="info.merchant_offline === 'OFFLINE'">线下</template>
          <template v-else-if="info.merchant_offline === 'MIX'">混合商户</template>
        </td>
      </tr>
      <tr>
        <!-- <td>实际经营地址:</td>
        <td>{{ info.store_address || '-' }}</td> -->
        <td>企业注册地址:</td>
        <td>{{ info.company_register_place || '-' }}</td>
        <td>门店地址:</td>
        <td>{{ info.merchant_store_address || '-' }}</td>
      </tr>
      <tr>
        <td>营业时间:</td>
        <td>{{ info.merchant_service_begin_time || '-' }}至{{ info.merchant_service_end_time || '-' }}</td>
        <td>商户网站:</td>
        <td>{{ info.merchant_url || '-' }}</td>
      </tr>
      <tr>
        <td>签约时间:</td>
        <td>{{ info.service_begin_date || '-' }}至{{ info.service_end_date || '-' }}</td>
        <td>商户简介:</td>
        <td>{{ info.merchant_describe || '-' }}</td>
      </tr>
      <tr>
        <td>商户备注:</td>
        <td colspan="3">{{ info.merchant_remark || '-' }}</td>
      </tr>
      <tr>
        <td>店铺照片:</td>
        <td colspan="3">
          <el-col v-for="(item, i) in storeImg" :key="i" class="storeImg">
            <a
              v-if="
                item.file_url.includes('pdf') ||
                item.file_url.includes('docx') ||
                item.file_url.includes('xlsx') ||
                item.file_url.includes('zip')
              "
              class="pdf"
              :href="item.file_url"
              target="_blank"
              >点击查看</a
            >
            <el-image v-else :src="item.file_url" fit="cover" />
          </el-col>
        </td>
      </tr>
    </table>
    <!-- 联络人信息 -->
    <p class="subtitle">联络人信息</p>
    <el-table :data="[info]" border>
      <el-table-column label="联系人姓名">
        <template slot-scope="{ row }">{{ row.contact_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="联系人邮箱">
        <template slot-scope="{ row }">{{ row.contact_email || '-' }}</template>
      </el-table-column>
      <el-table-column label="电话/手机">
        <template slot-scope="{ row }">{{ row.contact_phone || '-' }}</template>
      </el-table-column>
      <el-table-column label="客服电话">
        <template slot-scope="{ row }">{{ row.service_phone || '-' }}</template>
      </el-table-column>
    </el-table>
    <!-- 支付通道信息 -->
    <p class="subtitle">支付通道信息</p>
    <table class="lattice" v-if="wx_channel">
      <tr>
        <td>微信支付通道:</td>
        <td colspan="3">
          <span v-for="(item, i) in wx_channel.pay_channel" :key="i">
            微信{{ item.channel_type == 1 ? '线上' : '线下' }}&nbsp;
          </span>
        </td>
      </tr>
      <tr>
        <td>行业类别:</td>
        <td>{{ wx_channel.industry_class || '-' }}</td>
        <td>MCC Code:</td>
        <td>{{ wx_channel.industry_mince || '-' }}-{{ info.wx_mcc || '-' }}</td>
      </tr>
      <tr>
        <td>微信线上结算币种:</td>
        <td>
          <span v-for="item in wx_channel.wx_allow_currency" :key="item">{{ item }}&nbsp;</span>
        </td>
        <td>微信线下结算币种:</td>
        <td>
          <span v-for="item in wx_channel.wxfl_allow_currency" :key="item">{{ item }}&nbsp;</span>
        </td>
      </tr>
    </table>
    <table class="lattice" style="margin-top: 20px" v-if="ali_channel">
      <tr>
        <td>支付宝通道:</td>
        <td>
          <span v-for="(item, i) in ali_channel.pay_channel" :key="i">
            支付宝{{ item.channel_type == 1 ? '线上' : '线下' }}&nbsp;
          </span>
        </td>
        <td>通用范围:</td>
        <td>{{ ali_channel.industry_range || '-' }}</td>
      </tr>
      <tr>
        <td>商户大类:</td>
        <td>{{ ali_channel.industry_class || '-' }}</td>
        <td>商户细类:</td>
        <td>{{ ali_channel.industry_mince || '-' }}</td>
      </tr>
      <tr>
        <td>支付宝线上结算币种:</td>
        <td>
          <span v-for="item in ali_channel.ali_allow_currency" :key="item">{{ item }}&nbsp;</span>
        </td>
        <td>支付宝线下结算币种:</td>
        <td>
          <span v-for="item in ali_channel.alifl_allow_currency" :key="item">{{ item }}&nbsp;</span>
        </td>
      </tr>
    </table>
    <!-- KYC材料 -->
    <p class="subtitle">KYC材料</p>
    <el-table :data="kyc" border>
      <el-table-column label="文件类型">
        <template slot-scope="{ row }">{{ kycFile[row.type] || '-' }}</template>
      </el-table-column>
      <el-table-column label="文件名">
        <template slot-scope="{ row }">{{ row.file_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }"><a class="blue" :href="row.file_url" target="_blank" referrerpolicy="origin">查看文件</a></template>
      </el-table-column>
    </el-table>
    <!-- 商户提现账户 -->
    <p class="subtitle">商户提现账户</p>
    <el-table :data="bank_accounts" border>
      <el-table-column label="账户所属国家或地区">
        <template slot-scope="{ row }">{{ row.country | CouZH }}</template>
      </el-table-column>
      <el-table-column label="账户名称">
        <template slot-scope="{ row }">{{ row.bank_account_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="银行账号">
        <template slot-scope="{ row }">{{ row.bank_account_number || '-' }}</template>
      </el-table-column>
      <el-table-column label="开户银行名称">
        <template slot-scope="{ row }">{{ row.bank_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="开户支行名称">
        <template slot-scope="{ row }">{{ row.branch_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="接收币种">
        <template slot-scope="{ row }">{{ row.currency || '-' }}</template>
      </el-table-column>
      <el-table-column label="SwiftCode">
        <template slot-scope="{ row }">{{ row.swift_code || '-' }}</template>
      </el-table-column>
      <el-table-column label="Bank Code">
        <template slot-scope="{ row }">{{ row.bank_code || '-' }}</template>
      </el-table-column>
    </el-table>
    <!-- 签约合同文件 -->
    <p class="subtitle">签约合同文件</p>
    <el-table :data="contract" border>
      <el-table-column label="文件名">
        <template slot-scope="{ row }">{{ row.file_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }"><a class="blue" :href="row.file_url" target="_blank">查看文件</a></template>
      </el-table-column>
    </el-table>
    <!-- 初审补充文件 -->
    <!-- <p class="subtitle">初审补充文件</p>
    <el-table :data="supplement" border>
      <el-table-column label="文件名">
        <template slot-scope="{ row }">{{ row.file_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }"><a class="blue" :href="row.file_url" target="_blank">查看文件</a></template>
      </el-table-column>
    </el-table> -->
    <!-- 历史流转 -->
    <p class="subtitle">历史流转</p>
    <el-timeline>
      <el-timeline-item
        v-for="(item, i) in comments"
        :key="i"
        :icon="item.result == 'reject' ? 'el-icon-close' : 'el-icon-check'"
        :color="item.result == 'reject' ? '#F56C6C' : '#0bbd87'"
        size="large"
        :timestamp="`审核人员:${item.admin.realname}  所处流程:${risk_type[item.risk_type]}  审核结果:${
          result[item.result]
        }`"
        placement="top"
      >
        <el-card>
          <h4 style="margin-bottom: 10px">{{ item.created }}</h4>
          <p>{{ item.comment }}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <router-link
      v-if="merchant_status_now === 'START'"
      class="link"
      :to="{ name: 'DataReview', query: { merchant_no: $route.query.merchant_no } }"
    >
      <el-button type="success">立即修改</el-button>
    </router-link>
  </div>
</template>

<script>
import { scheduleMerchant } from '@/api/api'

export default {
  name: 'MerchantDetail', //商户详情
  data() {
    return {
      tableData: [],
      info: {},
      storeImg: [],
      wx_channel: null,
      ali_channel: null,
      kyc: [],
      kycFile: {
        certificate_file: '公司成立证明',
        director_file: '公司董事身份证件扫描件',
        shareholder_file: '持股25%及以上的个人股东身份证件扫描件',
        apply_in_file: '商户准入申请表',
        logo_file_url: '公司商标logo'
      },
      contract: [],
      supplement: [],
      comments: [],
      result: { reject: '驳回重提', pass: '审核通过', abolition: '拒绝开通', null: '-' },
      bank_accounts: [],
      risk_type: {
        START: '初始化',
        KYC: '风控初审',
        SECONDKYC: '风控复审',
        CONTRACT: '提交合同中',
        OPERATOR: '运营审核',
        ACTIVE: '已开通',
        ABOLITION: '拒绝开通',
        ADDITIONAL: '补充说明'
      },
      merchant_status_now: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      scheduleMerchant({ merchant_no: this.$route.query.merchant_no }).then((res) => {
        if (res.code === 200) {
          const [obj, file] = [res.data.merchant_basic, res.data.file]
          this.info = obj
          this.wx_channel = obj.wx_channel ? JSON.parse(obj.wx_channel) : null
          this.ali_channel = obj.ali_channel ? JSON.parse(obj.ali_channel) : null
          let kyc = []
          Object.keys(file).forEach((val) => {
            if (
              val === 'certificate_file' ||
              val === 'director_file' ||
              val === 'shareholder_file' ||
              val === 'apply_in_file'
              // val === 'logo_file_url'
            ) {
              file[val].forEach((item) => (item.type = val))
              kyc.push(...file[val])
            }
          })
          kyc.push({ type: 'logo_file_url', file_url: obj.logo_file_url })
          this.kyc = kyc
          this.contract = file.contract
          this.supplement = file.supplement
          this.storeImg = file.merchant_store_pic
          this.comments = res.data.comments
          this.bank_accounts = res.data.bank_accounts
          this.merchant_status_now = res.data.merchant_status_now.status
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lattice {
  width: 100%;
  margin-bottom: 20px;
  // 门店照片
  .storeImg {
    width: 130px;
    height: 130px;
    margin-right: 10px;
    .el-image,
    .pdf {
      width: 100%;
      height: 100%;
    }
    .pdf {
      display: inline-block;
      text-align: center;
      line-height: 130px;
      font-size: 14px;
      background-color: #f5f7fa;
      color: #c0c4cc;
    }
  }
}
// 立即修改
.link {
  display: block;
  width: 100%;
  text-align: center;
}
</style>
<style lang="scss">
.el-timeline-item__timestamp.is-top {
  word-spacing: 20px;
}
</style>


